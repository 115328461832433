import Popup from "reactjs-popup";
import styled from "styled-components";
import { usePopup } from "../context";
import { BankText } from "./bank";
import { Spacer } from "./components/spacer";
import { ExitButton } from "./popup";

type ModalPromptType = {
  open: boolean;
  closeModal?: () => void;
};

export const ModalPrompt = ({ open }: ModalPromptType) => {
  const { setIsModalOpen, position, options } = usePopup();
  const closeModal = () => {
    setIsModalOpen!(false);
  };

  const { channels = ["bank", "ussd", "card"] } = options;

  const wordMap = {
    bank: "Bank",
    ussd: "USSD",
    card: "Card",
  };

  const text = wordMap[channels[position as number]];

  return (
    <Popup open={open} closeOnDocumentClick onClose={closeModal} modal>
      <PopupWrapper>
        <BankText>You have a pending {text} transaction. Please close the popup and re-open to use another transaction channel</BankText>
        <Spacer height={20} />
        {/* <AppButton name="Proceed" isBusy={false} onClick={() => onProceed()} /> */}
        <ExitButton style={{ top: "-10px", background: "#e22b28" }} onClick={() => closeModal()}>
          <svg data-v-7f6ddd02="" width="10" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              data-v-7f6ddd02=""
              d="M5.572 4.033L8.89.71a.4.4 0 0 0-.566-.566L5.003 3.459 1.681.145a.4.4 0 0 0-.566.566L4.44 4.033
                    1.115 7.354a.398.398 0 0 0 0 .566.4.4 0 0 0 .566 0l3.322-3.33 3.322 3.33a.4.4 0 0 0 .566-.566L5.57 4.033z"
              fill="#fff"
            ></path>
          </svg>
        </ExitButton>
      </PopupWrapper>
    </Popup>
  );
};

export const PopupWrapper = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  max-width: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
