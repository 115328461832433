import axios from "axios";
import { Store } from "react-notifications-component";
import { baseQueryUrl, PELPAY_BASE_URL } from "../urls";
import numeral from "numeral";
import { ILoginData } from "../../hooks/useCardLogin";
import { IAdviceData } from "../../hooks/useCreateCardAdvice";
import { ProcessCardProps } from "../../popup/chamms-card";


axios.defaults.headers["domain"] = process.env.REACT_APP_DOMAIN ;
axios.defaults.headers["Content-Type"] = "application/json";

export const interceptor = () => {
  axios.interceptors.response.use(
    (config) => {
      return config;
    },

    (error) => {
      if (error.response && error.response.status === 403 && error.response.data.message === "Unauthorised Merchant") {
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: error?.response?.data.message,
          type: "danger",
        });
      }
      return Promise.resolve({ error });
    }
  );
};

export const fetchBanks = async (key: string) => {
  return axios({
    method: "GET",
    url: `${baseQueryUrl()}api/v1/webintegration/gb`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
  });
};

export const initiatePayment = async (key: string, data: any) => {
  return axios({
    method: "POST",
    url: `${baseQueryUrl()}api/v1/webintegration/ip`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
      // domain: "sandbox",
    },
    data,
  });
};

export const getConfirmPayment = async (ref: string, key: string) => {
  return axios({
    method: "GET",
    url: `${baseQueryUrl()}api/v1/webintegration/cp/${ref}`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
  });
};

export const getTerminatePayment = async (ref: string, key: string) => {
  return axios({
    method: "PUT",
    url: `${baseQueryUrl()}api/v1/webintegration/tp/${ref}`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
  });
};

export const initializeCardPayment = async (key: string, data: any) => {
  return axios({
    method: "POST",
    // url: `${"http://localhost:8075/"}api/v1/webintegration/cardpayment/initiatecardpayment`,
    url: `${baseQueryUrl()}api/v1/webintegration/cp/icp`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const login = async (data: ILoginData) => {
  return axios({
    method: "POST",
    url: `${PELPAY_BASE_URL}api/Account/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
};

export const createAdviceService = (data: IAdviceData) => {
  return axios({
    method: "POST",
    url: `${PELPAY_BASE_URL}Payment/advice`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
    data,
  });
};

export const processCardService = (data: ProcessCardProps, ref: string) => {
  return axios({
    method: "POST",
    url: `${PELPAY_BASE_URL}Payment/process/card/${ref}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
    data,
  });
};

export const completeCardService = (data: any) => {
  return axios({
    method: "POST",
    url: `${PELPAY_BASE_URL}Payment/complete/card`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
    data,
  });
};

export const confirmStatusService = (ref: string) => {
  return axios({
    method: "GET",
    url: `${PELPAY_BASE_URL}api/Transaction/bypaymentreference/${ref}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
  });
};

// export const cardCompletionStatus = async (key: string, data: any) => {
//   return axios({
//     method: "POST",
//     url: `${baseQueryUrl()}api/v1/webintegration/cp/s`,
//     // url: `${"http://localhost:8075/"}api/v1/webintegration/cardpayment/status`,
//     headers: {
//       publickey: key,
//       "Content-Type": "application/json",
//     },
//     data,
//   });
// };

export const checkReferenceStatus = async (key: string, ref: string) => {
  return axios({
    method: "PUT",
    url: `${baseQueryUrl()}api/v1/webintegration/crs/${ref}`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
  });
};

export const notifyFailureWebhook = async (key: string, data: any) => {
  return axios({
    method: "POST",
    url: `${baseQueryUrl()}api/v1/webintegration/nfwh`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const processCardProxy = async (key: string, data: any) => {
  return axios({
    method: "POST",
    url: `${baseQueryUrl()}api/v1/webintegration/p/c`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
      Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
    data,
    validateStatus: (status) => status < 300,
  });
};

export function numberWithCommas(x: string | number): string | number {
  const num = numeral(x).format("0,0.00");
  return num;
}

export function formatN(x: string | number): string | number {
  const num = numeral(x).format("0,0");
  return num;
}

export const banksWithUssd = (banks: any) => {
  const uniqueBankUssd = new Set();
  return (
    banks &&
    banks.filter((bank: any, idx: number) => {
      if (uniqueBankUssd.has(bank?.ussd)) {
        return false;
      } else {
        uniqueBankUssd.add(bank?.ussd);
        return bank?.ussd;
      }
    })
  );
};

export const banksWithAccountNumber = (banks: any) => {
  const uniqueBankName = new Set();
  return (
    banks &&
    banks.filter((bank: any, idx: number) => {
      if (uniqueBankName.has(bank?.name)) {
        return false;
      } else {
        uniqueBankName.add(bank?.name);
        return bank?.has_acc_no === true;
      }
    })
  );
};

export const maskData = (data: any) => {
  const maskedData = data.toString().replace(/[0-9A-Za-z]/g, "*");
  return maskedData;
};

export const errorParser = (text: string): string => {
  if (text.includes("400")) {
    return "This transaction cannot be processed the moment. Please try again";
  }

  return text;
};

export const accountLookUp = async (key: string, data: any) => {
  return axios({
    method: "POST",
    url: `${baseQueryUrl()}api/v1/webintegration/al`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
      Authorization: `bearer ${sessionStorage.getItem("pelpay_access_token") as string}`,
    },
    data,
    validateStatus: (status) => status < 300,
  });
};

export const inboundDownTimeCheck = async (key: string) => {
  return axios({
    method: "GET",
    url: `${baseQueryUrl()}api/v1/webintegration/inbound-downtime-check`,
    headers: {
      publickey: key,
      "Content-Type": "application/json",
    },
    validateStatus: (status) => status < 300,
  });
};

