import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";

export default function OverLayLoader({ active, children }) {
  return (
    <div>
      <LoadingOverlay
        active={active}
      >
        {children}
      </LoadingOverlay>
    </div>
  );
}
