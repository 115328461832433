import { useState, useEffect } from "react";
import { getConfirmPayment, numberWithCommas } from "../api/service";
import { ProjectEnvironment, baseQueryUrl, baseWebhookUrl } from "../api/urls";
import socketIOClient from "socket.io-client";
import { usePopup } from "../context";
import { Store } from "react-notifications-component";
import { EventName, postEvent } from "../dropin/events";
// import { EventName, postEvent } from "../dropin/events";

export const useInitialisePayment = (payment_option: string, setter: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    pinValue,
    options,
    setIsBankSet,
    setShowCharge,
    position,
    selectedBank,
  } = usePopup();

  const { AmountToShow } = useCalculatedAmount();

  const { qrid = "", pay_category = "", amount, } = options;

  let info = {
    live_mode: options?.domain === "live" ? true : false,
    amount: AmountToShow,
    customer_name: options.name,
    customer_account_number: pinValue!.slice(-4),
    customer_email: options.email,
    customer_phone: options.customer_phone ?? "",
    transactionref: options.transactionref,
    payment_option: payment_option,
    bankCode: position === 2 ? "110013" : selectedBank[0]?.code,
    original_amount: amount,
  };

  let addedOptionArray = { pay_category, qrid };
  Object.keys(addedOptionArray).forEach((option) => {
    if (addedOptionArray[option] !== "") {
      info[option] = addedOptionArray[option];
    }
  });

  const onClick = async () => {
    try {
      setLoading(true);
      // const res = await initiatePayment(options.key, info);
      const res = await fetch(`${baseQueryUrl()}api/v1/webintegration/ip`, {
        method: "POST",
        headers: {
          publickey: options.key,
          "Content-Type": "application/json",
          domain: options.domain,
        },
        body: JSON.stringify(info),
      });
      const data = await res.json();
      if (data.status) {
        setter!(data.data);
        setLoading(false);
        setIsBankSet!(true);
        setShowCharge!(false);
      } else {
        setLoading(false);
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: data?.message || "Error Occured",
          type: "danger",
        });
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error?.response);
      Store.addNotification({
        insert: "top",
        container: "top-right",
        message: error?.response?.data.message || "Error Occured",
        type: "danger",
      });
    }
  };

  return { loading, onClick };
};

export const useSocket = (payment_option: string) => {
  const {
    pinValue,
    options,
    isBankSet,
    selectedBank,
    merchantDetails: { account_number, vpay_account_number },
    setIsSuccess,
    position,
    showCharge,
    setPaymentPayload,
    setIsReversal,
  } = usePopup();

  const { AmountToShow } = useCalculatedAmount();

  const { onSuccess } = options
  useEffect(() => {
    const init = async () => {
      const socket = socketIOClient(baseWebhookUrl() as any, {
        transports: ["websocket"],
        forceNew: true,
      });
      socket.on("connect", () => {
        if (
          isBankSet &&
          !showCharge &&
          (account_number || vpay_account_number)
        ) {
          const info = {
            live_mode: options?.domain === "live" ? true : false,
            amount: AmountToShow,
            customer_name: options.name,
            customer_account_number: pinValue!.slice(-4),
            customer_email: options.email,
            customer_phone: options.customer_phone ?? "",
            transactionref: options.transactionref,
            payment_option,
            nuban: account_number || vpay_account_number,
            bankCode: position === 2 ? "110013" : selectedBank[0]?.code,
          };

          console.log("WEB_INTEGRATION", JSON.stringify(info))

          socket.emit("WEB_INTEGRATION", JSON.stringify(info));
        }
      });

      socket.on("WEB_INTEGRATION_FUNDING_SUCCESSFUL", (data) => {
        if (data) {
          let parsedData = JSON.parse(data);
          console.log(data, "WEB_INTEGRATION_FUNDING_SUCCESSFUL");
          const payload = {
            paymentstatus: "Successful",
            orderamount: parsedData?.amount,
            transactionref: parsedData?.transaction_ref,
          };
          
          setPaymentPayload!(payload);
          setIsSuccess!(true);
          setTimeout(() => {
            postEvent({
              target: window.parent,
              event: {
                name: EventName.RETURN,
                payload: {
                  message: `Transaction successful. Amount: ${AmountToShow}`,
                  code: "00",
                  payment_payload: payload,
                },
              },
            });
          }, 2000);
        }
      });
    };
    //

    const init2 = async () => {
      //SOCKET FOR REVERSAL ACTIONS


      const socket = socketIOClient(baseWebhookUrl() as string, {
        transports: ["websocket"],
        forceNew: true,
      });
      socket.on("connect", () => {
       
        if (
          isBankSet &&
          !showCharge &&
          (account_number || vpay_account_number)
        ) {
          const info = {
            live_mode: options?.domain === "live" ? true : false,
            amount: AmountToShow,
            customer_name: options.name,
            customer_account_number: pinValue!.slice(-4),
            customer_email: options.email,
            customer_phone: options.customer_phone ?? "",
            transactionref: options.transactionref,
            payment_option,
            nuban: account_number || vpay_account_number,
            bankCode: position === 2 ? "110013" : selectedBank[0]?.code,
          };

          const { amount, ...infoWithoutAmount } = info;

          console.log("REVERSAL___URL", JSON.stringify(infoWithoutAmount))

          socket.emit(
            "FAILED_WEB_INTEGRATION",
            JSON.stringify(infoWithoutAmount)
          );
        }
      });

      socket.on("WEB_INTEGRATION_FUNDING_FAILED", (data) => {
        if (data) {
          let parsedData = JSON.parse(data);
          console.log(parsedData, "parsedDataparsedData");
          setIsReversal!(true);
        }
      });
    };

    init();
    init2();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankSet, showCharge, account_number, vpay_account_number]);
};

export const useSentMoney = () => {
  const [loading, setLoading] = useState(false);
  const {
    options,
    setIsSuccess,
    isSuccess,
    isTimeLapsed,
    setPaymentPayload,
    setIsFailed,
  } = usePopup();
  const [intervalId, setIntervalId] = useState<any>(null);

  const { onSuccess } = options
  const { AmountToShow, calculatedCharge } = useCalculatedAmount();


  useEffect(() => {
    if (isSuccess || isTimeLapsed) {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isTimeLapsed]);

  const onClick = async () => {
    setLoading(true);
    try {
      const res = await getConfirmPayment(options.transactionref, options.key);

      console.log(res, "response");

      setPaymentPayload!(res?.data?.data);
      if (res.data?.data?.paymentstatus === "paid") {
        setIsSuccess!(true);
        setTimeout(() => {
          postEvent({
            target: window.parent,
            event: {
              name: EventName.RETURN,
              payload: {
                message: `Transaction successful. Amount: ${AmountToShow}`,
                code: "00",
                payment_payload: res?.data,
              },
            },
          });
        }, 2000);
      }
      if (res.data?.data?.paymentstatus === "failed") {
        setIsFailed!(true);
      }
    } catch (error: any) {
      console.error(error);
      Store.addNotification({
        insert: "top",
        container: "top-right",
        message: error?.response?.data?.message,
        type: "danger",
        dismiss: {
          duration: 3000,
          pauseOnHover: true,
        },
      });
      setLoading(false);
    }

    let newintervalID = setInterval(async () => {
      try {
        const res = await getConfirmPayment(
          options.transactionref,
          options.key
        );
        setPaymentPayload!(res?.data?.data);
        if (res?.data?.data?.paymentstatus === "paid") {
          setIsSuccess!(true);
          setTimeout(() => {
            postEvent({
              target: window.parent,
              event: {
                name: EventName.RETURN,
                payload: {
                  message: `Transaction successful. Amount: ${AmountToShow}`,
                  code: "00",
                  payment_payload: res?.data,
                },
              },
            });
          }, 2000);
        }
      } catch (error: any) {
        console.error(error);
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: 'Error: "The transaction has not been confirmed"',
          type: "danger",
          dismiss: {
            duration: 3000,
            pauseOnHover: true,
          },
        });
        setLoading(false);
      }
    }, 30000);
    setIntervalId(newintervalID);
  };

  return { loading, onClick };
};

export const useCalculatedAmount = () => {
  const { options } = usePopup();

  const { amount, txn_charge_type, txn_charge, currency, cap = 2000 } = options;

  const calculatedCharge =
    txn_charge_type === "flat"
      ? numberWithCommas(txn_charge)
      : ((
          (Number(numberWithCommas(txn_charge)) / 100) *
          Number(amount)
        ).toFixed(2) as any);

  const cappedCharge = cap > Number(calculatedCharge) ? calculatedCharge : cap;

  const calculateAmountToShow = Number(cappedCharge) + Number(amount);

  const AmountToShow =
    options?.txn_charge && options?.txn_charge_type
      ? calculateAmountToShow
      : amount;

  return {
    calculateAmountToShow,
    calculatedCharge: cappedCharge,
    amount,
    currency,
    txn_charge,
    AmountToShow: Math.ceil(AmountToShow),
  };
};

// LAZINESS MADE ME DO THIS...

/**
 * 
 *  00 is for successful transaction
    02 is for transaction timeout
    03 user cancelled the flow
    04 user exited the flow
    09 for failed transaction
 */

export const useExit = () => {
  const { AmountToShow } = useCalculatedAmount();
  const { isSuccess, isTimeLapsed, isBankSet, isFailed, paymentPayload } =
    usePopup();
  const ExitPopup = () => {
    if (isSuccess) {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.RETURN,
          payload: {
            message: `Transaction successful. Amount: ${AmountToShow}`,
            code: "00",
            payment_payload: paymentPayload,
          },
        },
      });
    } else {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.EXIT,
          payload: {
            message: isSuccess
              ? "Transaction Successful"
              : isTimeLapsed
              ? "Transaction expired"
              : isBankSet
              ? " User cancelled the transaction"
              : isFailed
              ? "Failed transaction"
              : "User exited the flow",
            code: isSuccess
              ? "00"
              : isTimeLapsed
              ? "02"
              : isBankSet
              ? "03"
              : isFailed
              ? "09"
              : "04",
          },
        },
      });
    }
  };

  return ExitPopup;
};
// LAZINESS MADE ME DO THIS...
