import styled from "styled-components";

export const TwoTextContainer = styled.div`
  width: 100%;        
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.1em;
`;

export const TwoTextTitle = styled.p`
  letter-spacing: 0.1em;
  line-height: 7px;
  color: #8E8E93;
  font-size: 11px;
`;
export const TwoTextContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  letter-spacing: -0.3px;
  color: #1C1C1E;
  font-size: 13px;
  font-weight: 500;

`;
