import { Fragment } from "react";
import { usePopup } from "../../../context";
import { EventName, postEvent } from "../../../dropin/events";
import { BankText } from "../../bank";
import { Spacer } from "../../components/spacer";
import { AshButton, MaxWidthWrapper } from "../../popup";
import { H3 } from "../../ussd";
import { Image } from "../../nav";
import { useCalculatedAmount, useSentMoney } from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import changePaymentIcon from "../../../assets/convertshape-2.svg";

export const ReversedTransaction = () => {
  const { options, isConfirmClicked, setIsConfirmClicked, setPosition } =
    usePopup();

  const { loading, onClick } = useSentMoney();

  const { AmountToShow } = useCalculatedAmount();

  const onClose = async () => {
    if (options.onExit) {
      options.onExit({});
    }
    postEvent({
      target: window.parent,
      event: {
        name: EventName.EXIT,
        payload: {
          message: isConfirmClicked
            ? "Timeout occurred before transaction confirmation"
            : "Timeout occurred without user taking payment action",
          code: isConfirmClicked ? "01" : "02",
        },
      },
    });
  };

  // const

  return (
    <Fragment>
      <ReversalSelectWrapper>
        <FlexRow>
          {/* <H3>Payment Error</H3> */}
          <Spacer height={40} />
          <FontAwesomeIcon
            style={{ color: "orange", fontSize: "20px" }}
            icon={faTriangleExclamation}
          />
        </FlexRow>
        <BankText>
          <b>
            {" "}
            We received a wrong amount, and a reversal/refund has been processed.
            Please transfer exactly NGN {AmountToShow} to complete this transaction.
          </b>
        </BankText>
      
        <Spacer height={20} />
        <Spacer height={16} />

        <MaxWidthWrapper>
          <AshButton
            onClick={() => {
              window.location.reload();
            }}
          >
            <div>Try again</div>
          </AshButton>
        </MaxWidthWrapper>
        <Spacer height={16} />
        <MaxWidthWrapper>
          <AshButton
            onClick={
              () => onClose()
              // setPosition(0)
            }
          >
            <Image src={changePaymentIcon} alt="close-payment-btn" />{" "}
            <div>Change Payment Method</div>
          </AshButton>
        </MaxWidthWrapper>
      </ReversalSelectWrapper>
    </Fragment>
  );
};

export const ReversalSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 292px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  column-gap: 5px;
`;
