import React, { useCallback, useEffect } from "react";
import { useCalculatedAmount } from ".";
import { createAdviceService } from "../api/service";
import { usePopup } from "../context";
import { ProjectEnvironment } from "../api/urls";

export interface IAdviceData {
  amount: number;
  currency: string;
  merchantRef: string;
  integrationKey: string;
  callBackUrl: string;
  shouldTokenizeCard: boolean;
  MerchantDescriptor: string;
  customer: {
    customerId: string;
    customerLastName: string;
    customerFirstName: string;
    customerEmail: string;
    customerPhoneNumber: string;
    customerAddress: string;
    customerCity: string;
    customerStateCode: string;
    customerPostalCode: string;
    customerCountryCode: string;
  };
}

export const useCreateCardAdvice = (initresponse: boolean) => {
  const { AmountToShow } = useCalculatedAmount();
  const [response, setResponse] = React.useState<any>({});
  const { setCardError, options, loginSuccessFul, merchantDetails } =
    usePopup();
    
  const integrationIdKey =
    ProjectEnvironment === "dev"
      ? process.env.REACT_APP_PELPAY_INTEGRATION_KEY_DEV
      : process.env.REACT_APP_PELPAY_INTEGRATION_KEY;

  const data: IAdviceData = {
    amount: AmountToShow,
    currency: options.currency ?? "NGN",
    merchantRef:
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15),
    integrationKey: integrationIdKey as string,
    callBackUrl: `${process.env.REACT_APP_BASE_URL}vpaycardwebhook`,
    shouldTokenizeCard: ProjectEnvironment === "dev"? false: true,
    MerchantDescriptor: "VPay",
    customer: {
      customerId:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      customerLastName: "Africa",
      customerFirstName: "VPay",
      customerEmail: "support@vpay.africa",
      customerPhoneNumber: "+23416309952",
      customerAddress: "Lagos",
      customerCity: "Lekki",
      customerStateCode: "",
      customerPostalCode: "101244",
      customerCountryCode: "NGA",
    },
  };

  const createAdvice = useCallback(async () => {

    try {
      if (initresponse) {
        const res = await createAdviceService(data);
        if (res === undefined) {
          setCardError!(true);
        }
        setResponse(res?.data);
      }
    } catch (error: any) {
      console.log(error);
      setCardError!(true);
    }
  }, [data, merchantDetails.businessname, initresponse]);


  React.useEffect(() => {
    if (loginSuccessFul) {
      createAdvice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccessFul, initresponse]);

  return response;
};
