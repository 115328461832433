import styled from "styled-components";
import { Image, PopupNav } from "./nav";

import ex from "../assets/ex.svg";
import round from "../assets/round.svg";

import vpayLogo from "../assets/vlogo.svg";
import bottom from "../assets/bottom.svg";
import { BankSection } from "./bank";
import { USSDSection } from "./ussd";
import { ScanQR } from "./scan";
import { Store as nStore } from "react-notifications-component";

import { Event, EventName, postEvent, waitEvent } from "../dropin/events";

import { useState, useEffect, useCallback, Fragment } from "react";
import { Spinner } from "./spinner";
import { numberWithCommas } from "../api/service";
import { usePopup } from "../context";
import { ModalPrompt } from "./modal";
import { useCalculatedAmount, useExit } from "../hooks";
import { baseQueryUrl } from "../api/urls";
import { CARD } from "./chamms-card";
import { useCardLogin } from "../hooks/useCardLogin";
import bank from "../assets/bank.svg";
import ussd from "../assets/call.svg";
import scan from "../assets/scan.svg";
import credit from "../assets/credit-card.svg";
import mobCloseCircle from "../assets/close-circle-mob.svg";
import changePaymentIcon from "../assets/convertshape-2.svg";
import useMediaQuery from "./components/use-mediaquery";
import { Spacer } from "./components/spacer";
import OverLayLoader from "./components/OverLayLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Store } from "react-notifications-component";
import { BankTextProps } from "../dropin/types";

export const SpinnerWrapper = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
);

export const notification = {
  insert: "top",
  container: "top-right",
};

export const SpinnerContainer = styled.div<BankTextProps>`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupWrapper = () => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const {
    setBanks,
    options,
    setOptions,
    isSuccess,
    position,
    setPosition,
    isModalOpen,
    setIsRefUsed,
    setGlobalError,
    setTriggerInitiate,
    isBankSet,
    isTransactionStarted,
    isDowntime,
  } = usePopup();

  const matches = useMediaQuery("(max-width: 600px)");

  const [isOverLayActive, setisOverLayActive] = useState(false);

  const [showNavBar, setShowNavBar] = useState(true);
  const {
    // customer_logo,
    theme_config = {
      background_color:
        "linear-gradient(71.72deg, #AE207C 0%, #E20E17 56.13%, #F7941F 109.97%)",
    },
  } = options;

  useEffect(() => {
    if (window.parent) {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.AUTH_REQUEST,
          payload: {},
        },
      });

      waitEvent({
        eventName: EventName.AUTH_CONFIRM,
        expectedOrigin: "*",
      }).then(async (event: Event) => {
        setShouldRender(true);
        setOptions!(event.payload.options);
        sessionStorage.removeItem("domain");
        sessionStorage.setItem("domain", event.payload.options?.domain);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.domain]);

  useEffect(() => {
    const getBanks = async () => {
      try {
        const data = await fetch(`${baseQueryUrl()}api/v1/webintegration/gb`, {
          headers: {
            publickey: options.key,
            "Content-Type": "application/json",
            domain: options.domain,
          },
        } as any);
        if (data.status === 200) {
          const banks = await data.json();
          setBanks!(banks.data);
        }
        if (data.ok === false) {
          if (data.status === 403) {
            nStore.addNotification({
              insert: "top",
              container: "top-right",
              message:
                "Invalid Authentication. Please check that you have entered the correct API key",
              type: "danger",
              dismiss: {
                duration: 5000,
              },
            });
          } else {
            nStore.addNotification({
              insert: "top",
              container: "top-right",
              message: "Something went wrong fetching banks",
              type: "danger",
              dismiss: {
                duration: 5000,
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (!options.key) return;
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.key]);

  useEffect(() => {
    (async () => {
      try {
        if (options?.transactionref) {
          let res = await fetch(
            `${baseQueryUrl()}api/v1/webintegration/crs/${
              options?.transactionref
            }`,
            {
              method: "PUT",
              headers: {
                publickey: options?.key,
                "Content-Type": "application/json",
                domain: options?.domain,
              },
            }
          );
          let parsedRes = await res.json();
          if (!parsedRes?.status) {
            Store.addNotification({
              insert: "top",
              container: "top-right",
              message: `${parsedRes?.message}`,
              type: "danger",
              dismiss: {
                duration: 200000,
              },
            });
            setGlobalError!(parsedRes?.message);
            setIsRefUsed!(true);
          } else {
            setTriggerInitiate!(true);
          }
        }
      } catch (error: any) {
        console.log(error.response);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.transactionref]);

  const ExitPopup = useExit();

  const [showCore, setShowCore] = useState<boolean>(true);

  const { channels = ["bank", "ussd", "card", "scan"] } = options;

  useEffect(() => {
    switch (true) {
      case !isDowntime?.["web.eft"]:
        setPosition(channels.indexOf(channels[0]));
        break;
      case !isDowntime?.["web.ussd"]:
        setPosition(channels.indexOf(channels[1]));
        break;
      case !isDowntime?.["web.card"]:
        setPosition(channels.indexOf(channels[2]));
        break;
      default:
        setPosition(channels.indexOf(channels[0]));
    }
  }, [isDowntime]);

  const goPosition = useCallback(() => {
    switch (position) {
      case channels.indexOf("bank"):
        return <BankSection />;
      case channels.indexOf("ussd"):
        return <USSDSection />;
      case channels.indexOf("card"):
        return <CARD setisOverLayActive={setisOverLayActive} />;
      case channels.indexOf("scan"):
        return <ScanQR />;
      default:
        return <Spinner />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, position]);

  const renderNavIcon = useCallback(() => {
    switch (position) {
      case channels.indexOf("bank"):
        return (
          <Fragment>
            <Image src={bank} width="20px" height="20px" />
            <MobileNavText>Bank Transfer</MobileNavText>
          </Fragment>
        );
      case channels.indexOf("ussd"):
        return (
          <Fragment>
            <Image src={ussd} width="20px" height="20px" />
            <MobileNavText>USSD</MobileNavText>
          </Fragment>
        );
      case channels.indexOf("card"):
        return (
          <Fragment>
            <Image src={credit} width="20px" height="20px" />
            <MobileNavText>CARD</MobileNavText>
          </Fragment>
        );
      case channels.indexOf("scan"):
        return (
          <Fragment>
            <Image src={scan} width="20px" height="20px" />
            <MobileNavText>SCAN</MobileNavText>
          </Fragment>
        );
      default:
        return <Spinner />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, position]);

  const { AmountToShow } = useCalculatedAmount();
  // LOGIN TO PELPAY CARD SERVICE WITH THE HOOK BELOW
  useCardLogin();

  const setPopupPosition = (e) => {
    if (matches) {
      setShowNavBar(false);
    }
    setPosition(e);
  };

  const changePaymentMethod = () => {
    setPosition(0);
    setShowNavBar(true);
  };

  const renderPopup = () => (
    <CheckoutWrapper>
      <OverLayLoader active={isOverLayActive}>
        <CheckoutSection>
          <CheckoutCard>
            <CheckoutBox
              style={{ borderTop: `${matches && "1px solid #ffffff"}` }}
            >
              {!isSuccess && (
                <>
                  {showNavBar && (
                    <CheckoutNavWrapper
                      backgroundColor={theme_config.background_color}
                    >
                      <PopupNav
                        setShowCore={setShowCore}
                        position={position}
                        setPosition={setPopupPosition}
                      />
                    </CheckoutNavWrapper>
                  )}
                </>
              )}

              {matches && !isSuccess && (
                <MobileNav
                  style={{
                    display: `${showNavBar || isSuccess ? "none" : "flex"}`,
                  }}
                >
                  {renderNavIcon()}
                </MobileNav>
              )}

              {showCore && (
                <>
                  <CheckoutCore>
                    {!isSuccess && (
                      <CoreHeader
                        className={showNavBar && matches ? "desktop-only" : ""}
                      >
                        <CorePaymentInfo>
                          <MerchantLogo>
                            <Image
                              className="host-image"
                              height="22.5px"
                              src={
                                // options?.customer_logo
                                //   ? options?.customer_logo
                                //   :
                                vpayLogo
                              }
                            />
                          </MerchantLogo>
                          <CustomerInfo>
                            <CustomerEmail>
                              {options?.email ?? "dropin-payments@vpay.africa"}
                            </CustomerEmail>
                            <TransactionAmount>
                              {isSuccess ? "Paid" : "Pay"}{" "}
                              <span>
                                {options?.currency ?? "NGN"}{" "}
                                {numberWithCommas(AmountToShow)}
                              </span>
                            </TransactionAmount>
                          </CustomerInfo>
                        </CorePaymentInfo>
                      </CoreHeader>
                    )}
                    <CheckoutStage
                      style={{
                        display: `${showNavBar && matches ? "none" : "flex"}`,
                      }}
                    >
                      <FormSection>{goPosition()}</FormSection>
                    </CheckoutStage>
                    <Spacer height="175px" className="mobile-only" />
                    {(!isBankSet || !isTransactionStarted) && (
                      <>
                        {!showNavBar && (
                          <MaxWidthWrapper>
                            <AshButton
                              style={{
                                // marginTop: "159px",
                                display: `${!matches && "none"}`,
                              }}
                              className="mobile-only"
                              onClick={() => changePaymentMethod()}
                            >
                              <Image
                                src={changePaymentIcon}
                                alt="close-payment-btn"
                              />{" "}
                              <div>Change Payment Method</div>
                            </AshButton>
                          </MaxWidthWrapper>
                        )}{" "}
                      </>
                    )}
                    <MaxWidthWrapper>
                      <TransparentButton
                        style={{
                          marginTop: "10px",
                          display: `${
                            (!matches || (matches && isSuccess)) && "none"
                          }`,
                        }}
                        className="mobile-only"
                        // ExitPopup();

                        onClick={() => {
                          ExitPopup();
                          window.location.reload();
                        }}
                      >
                        <Image src={mobCloseCircle} alt="close-btn" />{" "}
                        <div>Cancel Payment</div>
                      </TransparentButton>
                    </MaxWidthWrapper>

                    <CheckoutFooter>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.vpay.africa"
                      >
                        <Image height="15px" src={bottom} />
                      </a>
                    </CheckoutFooter>
                  </CheckoutCore>
                  {/* )} */}
                </>
              )}
              <ExitSection className="desktop-only">
                <ExitButton
                  onClick={() => {
                    ExitPopup();
                    window.location.reload();
                  }}
                >
                  <FontAwesomeIcon icon={faClose} />
                </ExitButton>
              </ExitSection>
            </CheckoutBox>
          </CheckoutCard>

          <ModalPrompt open={isModalOpen as boolean} />
        </CheckoutSection>
      </OverLayLoader>
    </CheckoutWrapper>
  );

  return shouldRender ? renderPopup() : <SpinnerWrapper />;
};

export { PopupWrapper };

export const ExpiredIcon = () => (
  <div style={{ position: "relative" }}>
    <img alt="round" src={round} />
    <img
      alt="ex"
      src={ex}
      style={{ position: "absolute", top: "33px", left: "30px" }}
    />
  </div>
);

export const CheckoutWrapper = styled.div<BankTextProps>`
  height: 100%;
  @media (max-width: 600px) {
    height: 100vh;
  }
`;

export const CheckoutSection = styled.section`
  @media (min-width: 600px) {
    max-width: 650px;
    padding: 100px 0px;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  @media (max-width: 600px) {
    background: #fff;
    min-height: 100%;
  }
`;

export const CheckoutCard = styled.div<BankTextProps>`
  @media (min-width: 600px) {
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 20%);
    min-height: 100%;
    border-radius: 4px;
    background-color: #fff;
  }

  @media (max-width: 600px) {
    background: #fff;
    border-radius: 4px;
  }
`;

export const CheckoutBox = styled.div<BankTextProps>`
  @media (min-width: 600px) {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (max-width: 600px) {
    min-height: 100%;
  }
`;


export const CheckoutNavWrapper = styled.div.attrs<BankTextProps>((props) => ({
  ...props,
}))<BankTextProps>`
  @media (min-width: 600px) {
    min-width: 160px;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #eee;
    -webkit-box-shadow: inset -4px 0 6px rgb(0 0 0 / 2%);
    box-shadow: inset -4px 0 6px rgb(0 0 0 / 2%);
    background: ${(props) => props.backgroundColor};
    position: relative;
  }

  @media (max-width: 600px) {
    background: #ffffff;
  }
`;

export const CheckoutCore = styled.div<BankTextProps>`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  // padding: 20px;
`;

export const CoreHeader = styled.header<BankTextProps>``;

export const CorePaymentInfo = styled.div<BankTextProps>`
  position: relative;
  padding: 0px 23.5px 0 23.5px;
  height: 68px;
  border-bottom: 1px solid #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
`;

export const MerchantLogo = styled.div<BankTextProps>`
  @media (max-width: 600px) {
    width: 56px;
  }
`;

export const CustomerInfo = styled.div<BankTextProps>`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
`;
export const CustomerEmail = styled.div<BankTextProps>`
  color: #636366;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const TransactionAmount = styled.div<BankTextProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #636366;
  margin-top: 1px;

  span {
    color: #29b263;
    font-weight: 700;
  }
`;

export const CheckoutStage = styled.div<BankTextProps>`
  margin: 38px 0 0 0px;
  // min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
`;

export const FormSection = styled.div<BankTextProps>`
  width: 100%;
`;

export const CheckoutFooter = styled.footer`
  position: relative;
  text-align: center;
  font-size: 1.1rem;
  color: #fff;
  height: 55px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;
  margin-top: 100px;

  @media (max-width: 600px) {
    margin-top: 37px;
  }

  &a {
    display: inline-block;
  }
`;

export const ExitSection = styled.div<BankTextProps>`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
`;

export const ExitButton = styled.button<BankTextProps>`
  position: absolute;
  top: -15px;
  right: -30px;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  border: none;

  &:hover {
    background-color: #e22b28;
    color: #ffffff;
  }
`;

export const MobileNav = styled.div<BankTextProps>`
  background: linear-gradient(
    71.72deg,
    #ae207c 0%,
    #e20e17 56.13%,
    #f7941f 109.97%
  );
  height: 68px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  column-gap: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

export const MobileNavText = styled.div<BankTextProps>`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #f8f8fc;
`;

export const TransparentButton = styled.button<BankTextProps>`
  display: flex;
  column-gap: 9.67px;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  color: #636366;
  // width: 355px;
  height: 46px;
  border: none;
`;

export const AshButton = styled.button<BankTextProps>`
  display: flex;
  column-gap: 9.67px;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  height: 46px;
  background: #f8f8fc;
  border: 1px solid #ebebf0;
  border-radius: 4px;
  width: 355px;

  @media (max-width: 600px) {
    width: 355px !important;
  }

  @media (max-width: 370px) {
    width: 282px !important;
  }

  @media (max-width: 311px) {
    width: 252px !important;
  }
`;

export const MaxWidthWrapper = styled.div<BankTextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 100%;
`;
