import { Fragment } from "react";
import { usePopup } from "../../../context";
import { EventName, postEvent } from "../../../dropin/events";
import { BankErrorText, BankText,  } from "../../bank";
import { Spacer } from "../../components/spacer";
import warningIcon from "../../../assets/warning-2.svg"
import { H3 } from "../../ussd";
import { Image } from "../../nav";
import { AppButton } from "../../components/app-button";
import { useCalculatedAmount, useSentMoney } from "../../../hooks";

export const ExpiredTransaction = () => {
  const { options, isConfirmClicked, setIsConfirmClicked } = usePopup();

  const { loading, onClick } = useSentMoney();
  const { AmountToShow } = useCalculatedAmount();
  const onConfrimClick = () => {
    setIsConfirmClicked!(true);
    onClick();
  };

  const onClose = async () => {
    if (options.onExit) {
      options.onExit({});
    }
    postEvent({
      target: window.parent,
      event: {
        name: EventName.EXIT,
        payload: {
          message: isConfirmClicked ? "Timeout occurred before transaction confirmation" : "Timeout occurred without user taking payment action",
          code: isConfirmClicked ? "01" : "02",
        },
      },
    });
  };

  // const

  return (
    <Fragment>
      {/* <ExpiredIcon /> */}
      <Image src={warningIcon} alt="warning-icon" />
      <Spacer height={20} />
      <H3>{isConfirmClicked ? "Confirmation Timeout !" : "Transaction Timeout !"}</H3>
      <Spacer height={15} />
      <BankErrorText>
        {/* {isConfirmClicked
          ? `We are sorry, but it has taken longer than expected to confirm this transaction. If you are sure you made a payment with the correct details, please contact ${options.customer_service_channel}`
          : "We are sorry, but it has taken longer than expected to complete this transaction. If you haven't made payment and wish to continue, kindly exit then try again."}{" "} */}
          We received a wrong amount, and a reversal/refund has been processed.
            Please transfer exactly NGN {AmountToShow} to complete this transaction.
      </BankErrorText>
      <Spacer height={20} />
      {/* <UnstyledButton onClick={() => onClose()}>Exit</UnstyledButton> */}
      <AppButton
          type="grayBtn"
          disabled={false}
          onClick={() => onClose()}
          isBusy={loading}
          name="Try again"
        />
          <Spacer height={16} />
      <AppButton
          type="grayBtn"
          disabled={false}
          onClick={() => onConfrimClick()}
          isBusy={loading}
          name="I've sent the money"
        />
    </Fragment>
  );
};
