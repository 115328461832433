import { ReactNotifications } from "react-notifications-component";
import { interceptor } from "./api/service";
import { PopupContextWrapper } from "./context";
import { PopupWrapper } from "./popup/popup";
export * from "./dropin/initialise";

function App() {
  // If theres no option
  interceptor();
  return (
    <PopupContextWrapper>
      <ReactNotifications />
      <PopupWrapper />
    </PopupContextWrapper>
  );
}

export default App;
