import React, { useEffect } from "react";
import { useCalculatedAmount } from ".";
import { initializeCardPayment } from "../api/service";
import { usePopup } from "../context";

export const useInitiateCardPayment = () => {
  const { AmountToShow } = useCalculatedAmount();
  const {
    options: { key, transactionref, email, qrid = "", pay_category = "", amount },
    setCardError,
    triggerInitiate,
    setMerchantDetails
  } = usePopup();
  const [response, setResponse] = React.useState(false);

  let data = {
    amount: AmountToShow,
    public_key: key,
    transactionref,
    email,
    original_amount: amount,
  };

  let addedOptionArray = { pay_category, qrid };
  Object.keys(addedOptionArray).forEach((option) => {
    if (addedOptionArray[option] !== "") {
      data[option] = addedOptionArray[option];
    }
  });
  useEffect(() => {
    (async () => {
      if (triggerInitiate) {
        try {
          const card_res = await initializeCardPayment(key, data);
          setMerchantDetails!(card_res?.data?.data)
          setResponse(true)
          if (card_res === undefined) {
            setCardError!(true);
          }
        } catch (error) {
          console.log(error);
          setCardError!(true);
          setResponse(false)
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerInitiate]);
  return response
};
