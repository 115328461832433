import { useEffect, useState } from "react";
import { EventName, postEvent } from "../../../dropin/events";
import { useExit } from "../../../hooks";
import { BankContainer, BankText, UnstyledButton } from "../../bank";
import { Spacer } from "../../components/spacer";
import { ExpiredIcon } from "../../popup";
import { H3 } from "../../ussd";

export const FailedTransaction = ({ errorMessage, onClose }: { errorMessage: string; onClose?: () => void }) => {
  const [time, setTime] = useState<number>(30);
  const exitAction = useExit();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    setTimeout(() => {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.EXIT,
          payload: {
            message: `Transaction failed`,
            code: "09",
          },
        },
      });
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BankContainer>
      <ExpiredIcon />
      <Spacer height={20} />
      <H3>Transaction Failed</H3>
      <Spacer height={15} />
      <BankText>{errorMessage}</BankText>
      <Spacer height={10} />
      <UnstyledButton onClick={() => exitAction()}>Redirecting in {time} seconds</UnstyledButton>
    </BankContainer>
  );
};
