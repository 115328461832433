import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import {
  getConfirmPayment,
  getTerminatePayment,
  initiatePayment,
  numberWithCommas,
} from "../api/service";
import { usePopup } from "../context";
import { Store } from "react-notifications-component";
import copy from "../assets/copy.svg";
import {
  useCalculatedAmount,
  useSentMoney,
  useSocket,
} from "../hooks";
import { ErrorWrapper, GenericRefErrorWrapper, StandardAppWidth } from "./ussd";
import { Timer } from "./timer";
import { ChargesSection } from "./charge";
import { TwoText } from "./components/two-text";
import { AppButton } from "./components/app-button";
import { Spacer } from "./components/spacer";
import { FailedTransaction } from "./views/failed-transaction";
import { SuccessfulTransaction } from "./views/sucessful-transaction";
import { SelectBankSection } from "./views/select-bank";
import { ProcessingTransaction } from "./views/processing-transaction";
import { ReversedTransaction } from "./views/reversed-transaction";
import { ExpiredBankTransaction } from "./views/expired-bank-transaction";
import { EventName, postEvent } from "../dropin/events";
import { BankTextProps } from "../dropin/types";

export const BankSection = () => {
  const [isError, setErrorState] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);

  const {
    merchantDetails: { account_name, account_number, bank_name, ...others },
    setMerchantDetails,
    options,
    pinValue,
    isSuccess,
    setIsTimeLapsed,
    isTimeLapsed,
    setIsBankSet,
    setIsConfirmClicked,
    showCharge,
    isBankSet,
    selectedBank,
    isFailed,
    isRefUsed,
    isConfirmClicked,
    isReversal,
    setIsSuccess,
    setPaymentPayload,
    customerName
  } = usePopup();

  const { loading, onClick } = useSentMoney();
  const { AmountToShow, calculatedCharge } = useCalculatedAmount();

  const onBankButtonClick = () => {
    setIsConfirmClicked!(true);
    onClick();
  };

  useSocket("bank");
  const {
    txn_charge,
    txn_charge_type,
    qrid = "",
    pay_category = "",
    amount,
    onSuccess
  } = options;

  console.log(customerName, 'customerNameJaa')
  let info = {
    live_mode: false,
    amount: AmountToShow,
    // customer_name: options.name,
    customer_name: customerName,
    customer_account_number: pinValue!.slice(-4),
    customer_email: options.email,
    customer_phone: options.customer_phone ?? "",
    transactionref: options.transactionref,
    payment_option: "bank",
    bankCode: selectedBank[0]?.code,
    original_amount: amount,
    full_customer_account_number: pinValue
  };

  let addedOptionArray = { pay_category, qrid };
  Object.keys(addedOptionArray).forEach((option) => {
    if (addedOptionArray[option] !== "") {
      info[option] = addedOptionArray[option];
    }
  });

  useEffect(() => {
    if (isSuccess || isTimeLapsed) {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isTimeLapsed]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await initiatePayment(options.key, info);
        setMerchantDetails!(res.data.data);
        if (!res.data.data.account_number || !res.data.data.account_name) {
          setErrorState(true);
        }
        setIsBankSet!(true);
        let newintervalID = setInterval(async () => {
          try {
            const res = await getConfirmPayment(
              options.transactionref,
              options.key
            );
            setPaymentPayload!(res?.data?.data);
            if (res?.data?.data?.paymentstatus === "paid") {
              setIsSuccess!(true);
              setTimeout(() => {
                postEvent({
                  target: window.parent,
                  event: {
                    name: EventName.RETURN,
                    payload: {
                      message: `Transaction successful. Amount: ${AmountToShow}`,
                      code: "00",
                      payment_payload: res?.data,
                    },
                  },
                });
              }, 2000);
            }
          } catch (error: any) {
            console.error(error);
            Store.addNotification({
              insert: "top",
              container: "top-right",
              message: 'Error: "The transaction has not been confirmed"',
              type: "danger",
              dismiss: {
                duration: 3000,
                pauseOnHover: true,
              },
            });
            // setLoading(false);
          }
        }, 30000);
        setIntervalId(newintervalID);
      } catch (error: any) {
        console.log("Error:", error?.response?.data?.message);
        Store.addNotification({
          insert: "top",
          container: "top-right",
          message: error?.response?.data?.message ?? "An error occured.",
          type: "danger",
        });
      }
    };

    isBankSet &&
      !isSuccess &&
      (txn_charge && txn_charge_type ? !showCharge : true) &&
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankSet, isSuccess]);

  const onTimeStopped = async () => {
    setIsTimeLapsed!(true);
    try {
      await getTerminatePayment(options.transactionref, options.key);
    } catch (error: any) {
      console.log(error);
    }
  };

  const renderInfo = () => (
    <Fragment>
      {!isConfirmClicked ? (
        <BankSelectWrapper>
          {!isError && (
            <Fragment>
              <BankText>
                Transfer {options?.currency ?? "NGN"}{" "}
                {numberWithCommas(AmountToShow)} to {account_name}
              </BankText>
              <Spacer height={25} />
              <BankDetailsWrapper>
                <TwoText topText="BANK NAME" bottomText={bank_name} icon={""} isCurrency={false}/>
                <TwoText
                  topText="ACCOUNT NUMBER"
                  bottomText={account_number}
                  icon={copy}
                  isCurrency={false}
                />
                <TwoText
                  topText="AMOUNT"
                  bottomText={JSON.stringify(AmountToShow)}
                  icon={copy}
                  isCurrency={true}
                />
                <ChargeWrapper>Fee {calculatedCharge}</ChargeWrapper>
              </BankDetailsWrapper>
              <Spacer height={20} />
              <BankText style={{ color: "red" }}>
                <StandardAppWidth >
                <PaymentAccountWarning>
                  <ul style={{paddingLeft:"10px"}}>
                    <li >
                      The account details provided by you will be used to
                      verify this transaction
                    </li>
                    <li>Send exact amount to checkout successfully</li>
                  </ul>
                </PaymentAccountWarning>
                </StandardAppWidth>
          
              </BankText>

              <Spacer height={25} />
              <Timer onStop={() => onTimeStopped()} time={2} />
              <Spacer height={30} />
              <AppButton
                type="red"
                disabled={false}
                onClick={() => onBankButtonClick()}
                isBusy={loading}
                name="I've sent the money"
              />
            </Fragment>
          )}
          <ErrorWrapper isError={isError} type="Bank" />
        </BankSelectWrapper>
      ) : (
        <ProcessingTransaction />
      )}
    </Fragment>
  );

  const ErrorTxt = `Please check your wallet balance, if you have been debited kindly reach out to ${
    options.customer_service_channel || ""
  } and try again.`;

  const renderHelper = () => {
    if (isRefUsed) {
      return <GenericRefErrorWrapper />;
    }
    if (!isBankSet) {
      return <SelectBankSection />;
    } else {
      if (isSuccess) return <SuccessfulTransaction />;
      if(isReversal) return <ReversedTransaction />;
      if (isTimeLapsed) return <ExpiredBankTransaction />;
      if (showCharge) return <ChargesSection payment_option="bank" />;
      if (isFailed) return <FailedTransaction errorMessage={ErrorTxt} />;
      return renderInfo();
    }
  };

  return <BankContainer>{renderHelper()}</BankContainer>;
};

export const BankContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const BankText = styled.p<BankTextProps>`
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1c1c1e;
  font-weight: 500;
`;

export const BankErrorText = styled.p`
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1c1c1e;
  padding: 0 10px;
`;

export const BankDetailsWrapper = styled.div`
  background: #f8f8fc;
  border-radius: 6px;
  box-sizing: border-box;
  min-width: 282px;
  // max-width: 400px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 219px;
  &:not(:last-of-type) {
    row-gap: 22px;
  }

  @media (max-width: 600px) {
    width: 355px !important;
  }

  @media (max-width: 370px) {
    width: 282px !important;
  }

  @media (max-width: 311px) {
    width: 252px !important;
  }
`;

export const UnstyledButton = styled.button<BankTextProps>`
  background: none;
  cursor: pointer;
  border: 0;
  color: red;
  font-weight: 500;
`;

export const GreyButton = styled.button<BankTextProps>`
  background: #f8f8fc;
  cursor: pointer;
  border: 1px solid #ebebf0;
  border-radius: 4px;
  color: #636366;
  font-weight: 500;
`;

export const TextLabel = styled.p`
  text-align: center;
  // max-width: 57.5%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 0 1px;
  letter-spacing: 0.01em;
  color: #1c1c1e;
  // line-height: 5px;
`;

export const WarningText = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: red;
  border: 0.7px solid #fbb0b3;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  // line-height: 5px;

 
`;

export const BankSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ChargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e7f8f0;
  border-radius: 2000px;
  padding: 3px 6px;
  // width: 40px;
  height: 13px;
  // column-gap: 10px;
  color: #12b76a;
  font-weight: 500;
  font-size: 8px;
  margin-top: -15px;
`;

export const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebf0;
  padding: 0 20px;
  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  height: 38px;
`;

export const TimerText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #636366;
  letter-spacing: 0.01em;
`;

export const NameDisplay = styled.div`
box-sizing: border-box;
  // text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #ae207c;
  height: 40px;
  width: 100%;
  place-self: flex-start;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7e9f2;
  padding: 0 5px;


`;

export const PaymentAccountWarning = styled.div`
box-sizing: border-box;
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  color: #DD000A;
  // height: 26px;
  width: 100%;
  // place-self: flex-start;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  // background: #f7e9f2;
  padding-left: 15px;
  padding-right: 8px;
  border: 0.7px solid #FBB0B3;
  line-height: 18px;

`;
