import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const LinearProgressWithTimer = ({ durationInMinutes = 20 }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = new Date().getTime();

    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;

      // Calculate progress based on elapsed time, incrementing by 2 every second
      const calculatedProgress = (elapsedTime / (durationInMinutes * 60 * 1000)) * 100 * 1.03;

      // Set progress, ensuring it doesn't exceed 100%
      setProgress(Math.min(calculatedProgress, 100));
    }, 1000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, durationInMinutes * 60 * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [durationInMinutes]);

  return (
    <div>
      {/* <h1>Progress Bar</h1> */}
      <LinearProgress color="inherit" variant="determinate" value={progress} />
      {/* <p>{`${progress.toFixed(2)}%`}</p> */}
    </div>
  );
};

export default LinearProgressWithTimer;
