import React from "react";
import { login } from "../api/service";
import { usePopup } from "../context";
import { ProjectEnvironment } from "../api/urls";
// d315e827-ca49-4095-9966-d2ab0702ef84
export interface ILoginData {
  clientId: string;
  clientSecret: string;
}

export const useCardLogin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setResponse] = React.useState<any>({});
  const { setCardError, setLoginSuccessFul } = usePopup();

  const clientIdKey =
    ProjectEnvironment === "dev"
      ? process.env.REACT_APP_CLIENT_ID_DEV
      : process.env.REACT_APP_CLIENT_ID;

  const clientSecretKey =
    ProjectEnvironment === "dev"
      ? process.env.REACT_APP_CLIENT_SECRET_DEV
      : process.env.REACT_APP_CLIENT_SECRET;

  const data: ILoginData = {
    clientId: clientIdKey as string,
    clientSecret: clientSecretKey as string,
  };

  const onLogin = async () => {
    try {
      const res = await login(data);
      if (res === undefined) {
        setCardError!(true);
      }
      setResponse(res?.data);
      setLoginSuccessFul!(true);
      sessionStorage.setItem("pelpay_access_token", res?.data?.access_token);
    } catch (error) {
      console.log(error);
      setCardError!(true);
    }
  };

  React.useEffect(() => {
    onLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
