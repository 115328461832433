import { BankContainer, BankText, UnstyledButton } from "./bank";
import QRCode from "qrcode.react";
import { Fragment, useState, useEffect } from "react";
import { usePopup } from "../context";
import { useCalculatedAmount, useExit, useSentMoney, useSocket } from "../hooks";
import { Spinner } from "./spinner";
import { getTerminatePayment, initiatePayment } from "../api/service";
import { ErrorWrapper } from "./ussd";
import { Timer } from "./timer";
import { ChargesSection } from "./charge";
import { AppButton } from "./components/app-button";
import { Spacer } from "./components/spacer";
import { ExpiredTransaction } from "./views/expired-transaction";
import { SuccessfulTransaction } from "./views/sucessful-transaction";
import { SelectBankSection } from "./views/select-bank";

const ScanQR = () => {
  const {
    isSuccess,
    isTimeLapsed,
    // merchantDetails: { time_to_leave },
    setIsTimeLapsed,
    pinValue,
    options,
    setIsBankSet,
    setMerchantDetails,
    setIsConfirmClicked,
    showCharge,
    isBankSet,
  } = usePopup();
  // const { loading, data, setData } = useInitialisePayment("qrcode");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [isError, setErrorState] = useState(false);

  const { AmountToShow } = useCalculatedAmount();
  const { qrid = "", pay_category = "", amount } = options;

  let info = {
    live_mode: false,
    amount: AmountToShow,
    customer_name: options.name,
    customer_account_number: pinValue!.slice(-4),
    customer_email: options.email,
    customer_phone: options.customer_phone ?? "",
    transactionref: options.transactionref,
    payment_option: "qrcode",
    bankCode: "110013",
    original_amount: amount,
  };

  const { loading: btnLoading, onClick } = useSentMoney();
  useSocket("qrcode");

  let addedOptionArray = { pay_category, qrid };
  Object.keys(addedOptionArray).forEach((option) => {
    if (addedOptionArray[option] !== "") {
      info[option] = addedOptionArray[option];
    }
  });
  const onScanButtonClick = () => {
    setIsConfirmClicked!(true);
    onClick();
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const res = await initiatePayment(options.key, info);
        setData!(res.data.data);
        setMerchantDetails!(res.data.data);
        if (!res.data.data.qrcode) {
          setErrorState(true);
        }
        setIsBankSet!(true);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };

    !isSuccess && isBankSet && !showCharge && init();

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankSet, isSuccess, showCharge]);

  const onTimeStopped = async () => {
    setIsTimeLapsed!(true);
    try {
      await getTerminatePayment(options.transactionref, options.key);
    } catch (error: any) {
      console.log(error);
    }
  };

  const ExitPopup = useExit();

  const renderInfo = () => (
    <Fragment>
      {!isError && (
        <Fragment>
          <BankText>Scan the N-QR code below from your bank mobile app to complete this payment.</BankText>
          <Spacer height={20} />
          <QRCode value={data.qrcode ?? "Nothng here"} height="5em" width="4em" />
          <Spacer height={20} />
          <AppButton type="redBtn"   disabled={false} onClick={() => onScanButtonClick()} isBusy={btnLoading} name="I have sent the money" />
          <Spacer height={15} />
          <Timer onStop={() => onTimeStopped()} time={2} />
          <UnstyledButton style={{ paddingTop: "2em", fontWeight: "bold" }} onClick={() => ExitPopup()} className="mobile-only">
            Close
          </UnstyledButton>
        </Fragment>
      )}
      <ErrorWrapper isError={isError} type="N-QR Code" />
    </Fragment>
  );

  const renderHelper = () => {
    if (!isBankSet) {
      return <SelectBankSection />;
    } else {
      if (loading) return <Spinner />;
      if (isSuccess) return <SuccessfulTransaction />;
      if (isTimeLapsed) return <ExpiredTransaction />;
      if (showCharge) return <ChargesSection payment_option="qrcode" />;
      return renderInfo();
    }
  };

  return (
    <BankContainer>
      {/* {!isBank && <SelectBankSection setter={() => setBankState(true)} />}
      {isBank && (loading ? <Spinner /> : isSuccess ? <SuccessfulTransaction /> : isTimeLapsed ? <ExpiredTransaction /> : renderInfo())} */}
      {renderHelper()}
    </BankContainer>
  );
};

export { ScanQR };
