import { Store } from "react-notifications-component";
import { Image } from "../../nav";
import { Spacer } from "../spacer";
import { TwoTextContainer, TwoTextContent, TwoTextTitle } from "./styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const TwoText = ({
  topText,
  bottomText,
  icon,
  isCurrency,
}: {
  topText: string;
  bottomText: string;
  icon: string;
  isCurrency: boolean;
}) => {

  const onCopyToClipboard = () => {
    Store.addNotification({
      insert: "top",
      container: "top-right",
      message: "Copied to clipboard",
      type: "success",
      dismiss: {
        duration: 2000,
      },
    });
  };



  return (
    <TwoTextContainer>
      <TwoTextTitle>{topText}</TwoTextTitle>
      <Spacer height={10} />
      <TwoTextContent>
        {isCurrency ? (
          <>NGN {Number(bottomText).toLocaleString('en-US')}</>
        ) : (
          <>{bottomText}</>
        )}{" "}
        {icon && (
          <>
            <CopyToClipboard text={bottomText} onCopy={onCopyToClipboard}>
              <Image
                style={{ cursor: "pointer" }}
                src={icon}
              />
            </CopyToClipboard>
          </>
        )}{" "}
      </TwoTextContent>
    </TwoTextContainer>
  );
};
