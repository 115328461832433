import { useCalculatedAmount } from ".";
import { notifyFailureWebhook } from "../api/service";
import { usePopup } from "../context";

export const useFailedTransaction = () => {
  const { options, position } = usePopup();
  const { AmountToShow } = useCalculatedAmount();
  const { channels = ["bank", "ussd", "card"] } = options;
  const wordMap = {
    bank: "Bank",
    ussd: "USSD",
    card: "Card",
  };

  const getFailureOrigin = () => {
    return wordMap[channels[position as number]];
  };

  const sendFailureWebhook = async () => {
    try {
      await notifyFailureWebhook(options?.key, {
        transactionref: options?.transactionref,
        amount: AmountToShow,
        failureOrigin: getFailureOrigin(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return sendFailureWebhook;
};
