export const baseQueryUrl = () => {
  let baseUrl = process.env.REACT_APP_BASE_URL;
  return baseUrl;
};

export const baseWebhookUrl = () => {
  let baseUrl = process.env.REACT_APP_WEBHOOK_BASE_URL;
  return baseUrl;
};

export const ProjectEnvironment = process.env.REACT_APP_ENV;

export const PELPAY_BASE_URL =
  ProjectEnvironment === "dev"
    ? process.env.REACT_APP_PELPAY_BASE_URL_STAGING
    : process.env.REACT_APP_PELPAY_BASE_URL_PROD;

export const cardinalUrl = async () => {
  let url =  ProjectEnvironment === "dev"
  ? process.env.REACT_APP_CARDINAL_URL_STAGING
  : process.env.REACT_APP_CARDINAL_URL_PROD;
  return url;
};


