import { forwardRef } from "react";
import { GrayButton, RedButton } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { usePopup } from "../../../context";

export const AppButton = forwardRef(
  (
    {
      onClick,
      isBusy,
      name,
      id,
      disabled,
      type,
      ...rest
    }: {
      onClick?: any | (() => void);
      isBusy: boolean;
      disabled: boolean;
      type: string;
      name: any;
      id?: string;
    },
    ref: any
  ) => {
    const { options } = usePopup();
    const { theme_config = { action_btn_color: "#ed2b30" } } = options;

    return (
      <>
        {type == "grayBtn" ? (
          <>
            <GrayButton
              bg_color={"#F8F8FC"}
              id={id}
              ref={ref}
              onClick={!isBusy ? onClick : null}
            >
              {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
            </GrayButton>
          </>
        ) : (
          <RedButton
            bg_color={!disabled ? theme_config.action_btn_color : "#F98A8F"}
            id={id}
            ref={ref}
            onClick={!isBusy ? onClick : null}
          >
            {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
          </RedButton>
        )}
      </>
    );
  }
);
